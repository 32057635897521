import React from 'react';

import EmailIcon from '../../svg/email.svg';
import FacebookIcon from '../../svg/facebook.svg';
import InstagramIcon from '../../svg/instagram.svg';
import PinterestIcon from '../../svg/pinterest.svg';

const Footer = () => {
  return (
    <footer className="h-16 pt-12 w-full">
      <div className="px-12 h-full w-full flex justify-center space-x-2">
        <a href="https://www.instagram.com/petraflucka" target="_blank" rel="noopener noreferrer">
          <InstagramIcon className="w-6" />
        </a>
        <a href="mailto:hello@petraflucka.com">
          <EmailIcon className="w-6" />
        </a>
        <a href="https://www.pinterest.at/petraflucka" target="_blank" rel="noopener noreferrer">
          <PinterestIcon className="w-6" />
        </a>
        <a href="https://www.facebook.com/petra.hlavnova" target="_blank" rel="noopener noreferrer">
          <FacebookIcon className="w-6" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
